<template>
  <div class="listing__items listing__container listing--blur">
    <div class="list-item-wrap" v-for="(item, index) in fakeListItems">
      <div class="list-item">
				<div class="list-item__img">
					<img :src="item.img" alt="">
				</div>
        <div class="list-item__header">
					<div class="list-item__date">{{ item.date }}</div>
          <div class="list-item__title">{{ item.title }}</div>
					<div class="list-item__price">{{ item.price }}</div>
					<div class="list-item__addr" v-html="item.addr"></div>
					<div class="list-item__metro" v-html="item.metro"></div>
        
					
        </div>
        <div class="list-item__footer">
          
          <div class="list-item__counts">{{ item.counts }}</div>
        </div>
      </div><!--end list-item -->
    </div><!--end list-item-wrap -->

  </div>
</template>
<style lang="scss" scoped>

.result-text {
	font-weight: 500;
	color: #1D2939;
	margin-bottom: 28px;
	span {
		color: #667085;
	}
}
.listing__items {
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1060px;
	margin-left: -10px;
	margin-right: -10px;
  grid: none;
  grid-gap: unset;
  grid-template: none;
  overflow: auto;
  height: auto;
  padding: 0 0 10px;
	@media(max-width: 650px) {
		margin-left: 0;
	}
}

.listing--blur {
	filter:blur(4px)
}


.list-item {
  border: 1px solid #E4E7EC;
  padding: 20px;
  color: #667085;
  font-size: 14px;
  line-height: 1.25;
  display: flex;
  flex-direction: column;
  
  &-wrap {
    width: 33.33%;
    padding: 10px;
    @media(max-width: 768px) {
      width: 50%;
    }
    @media(max-width: 650px) {
      width: 100%;
      padding: 10px 0;
    }
  }
  
  &__header {
    padding-bottom: 16px;
    flex: 1 1 auto;
  }
  
  &__title {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 0;
		color: #1D2939;
  }
  &__img {
		margin: -20px -20px 16px;
	}
	&__date {
		font-size: 14px;
		color: #667085;
		padding-bottom: 4px;
	}
  &__addr {
    padding-bottom: 4px;
		font-size: 14px;
		color: #1D2939;
		font-weight: 300;
		svg {
			position: relative;
			top: 4px;
		}
  }
  
  &__footer {
    padding-top: 16px;
    font-size: 16px;
  }
  &__metro {
		color: #1D2939;
		font-weight: 300;
		font-size: 14px;
		position: relative;
		padding-left: 12px;
		&:after {
			content: "";
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: #E42222;
			position: absolute;
			top: 4px;
			left: 0;
		}
		span {
			display: inline-block;
			vertical-align: top;
			margin-left: 4px;
			color: #667085;
		}
	}
  &__price {
    font-weight: 500;
		color: #667085;
		font-size: 20px;
		padding-bottom: 8px;
  }
  
  &__counts {
		font-size: 16px;
		width: 100%;
		padding: 20px;
		border-radius: 8px;
		background: linear-gradient(43deg, #415EF7 0%, #FC476C 100%);
		color: #fff;
		text-align: center;
	}
  
  .list-item__go {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
		font-weight: 300;
    li {
      display: flex;
      margin-right: 3px;
      align-items: center;
    }
    
    .metro-icon {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: top;
      margin-right: 8px;
      position: relative;
      top: -2px;
    }
    
    svg {
      position: relative;
      top: -2px;
    }
  }
}
</style>
<script>
import {Fragment} from 'vue-fragment'

export default {
  //props: ,
  components: {
    'Fragment': Fragment
  },
  data() {
    return {}
  },
  computed: {
    fakeListItems() {
      let fakeResult;
  
      if(document.body.getAttribute('data-region') === 'spb'){
        fakeResult = [
          {
						img: "/images/quiz/ii1.png",
						date: "Сдача в 2024 г., есть сданные",
            title: "Чистое небо",
            addr: 'Санкт-Петербург <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 12.5L10 8.5L6 4.5" stroke="#98A2B3" stroke-linecap="round" stroke-linejoin="round"/></svg> Приморский',
            metro: "Комендантский проспект <span>15 мин на машине</span>",
            goType: "car",
            time: "15 мин",
            price: "от 4.9 до 16.4 млн руб",
            counts: "Осталось 31 квартир",
          },
          {
						img: "/images/quiz/ii2.png",
						date: "Сдача в 2024 г., есть сданные",
            title: "Солнечный город",
            addr: 'Санкт-Петербург <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 12.5L10 8.5L6 4.5" stroke="#98A2B3" stroke-linecap="round" stroke-linejoin="round"/></svg> Красносельский',
            metro: "Ленинский проспект <span>25 мин на машине</span>",
            goType: "car",
            time: "25 мин",
            price: "от 4.1 до 13 млн руб",
            counts: "Осталось 15 квартир",
          },
          {
						img: "/images/quiz/ii3.png",
						date: "Сдача в 2024 г., есть сданные",
            title: "Полис Приморский",
            addr: 'Санкт-Петербург <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 12.5L10 8.5L6 4.5" stroke="#98A2B3" stroke-linecap="round" stroke-linejoin="round"/></svg> Приморский',
            metro: "Комендантский проспект <span>15 мин на машине</span>",
            goType: "car",
            time: "15 мин",
            price: "от 4.8 до 13 млн руб",
            counts: "Осталось 28 квартир",
          },
          {
						img: "/images/quiz/ii4.png",
						date: "Сдача в 2024 г., есть сданные",
            title: "Солнечный город",
            addr: 'Санкт-Петербург <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 12.5L10 8.5L6 4.5" stroke="#98A2B3" stroke-linecap="round" stroke-linejoin="round"/></svg> Красносельский',
            metro: "Проспект Ветеранов <span>20 мин на машине</span>",
            goType: "car",
            time: "20 мин",
            price: "от 4.5 до 16.3 млн руб",
            counts: "Осталось 42 квартир",
          },
          
        ]
      }else {
        fakeResult = [
          {
						img: "/images/quiz/ii1.png",
						date: "Сдача в 2024 г., есть сданные",
            title: "Митинский лес",
            addr: 'Москва <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 12.5L10 8.5L6 4.5" stroke="#98A2B3" stroke-linecap="round" stroke-linejoin="round"/></svg> СЗАО',
            metro: "Пятницкое шоссе <span>20 мин пешком</span>",
            goType: "people",
            time: "20 мин",
            price: "от 5.4 до 16 млн руб",
            counts: "Осталось 7 квартир",
          },
          {
						img: "/images/quiz/ii2.png",
						date: "Сдача в 2024 г., есть сданные",
            title: "Зеленая вертикаль",
            addr: 'Москва <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 12.5L10 8.5L6 4.5" stroke="#98A2B3" stroke-linecap="round" stroke-linejoin="round"/></svg> ЮАО',
            metro: "Лесопарковая <span>3 мин пешком</span>",
            goType: "people",
            time: "3 мин",
            price: "от 9.9 до 21.6 млн руб",
            counts: "Осталось 21 квартир",
          },
          {
						img: "/images/quiz/ii3.png",
						date: "Сдача в 2024 г., есть сданные",
            title: "PROFIT",
            addr: 'Москва <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 12.5L10 8.5L6 4.5" stroke="#98A2B3" stroke-linecap="round" stroke-linejoin="round"/></svg> ЮВАО',
            metro: "Нижегородская <span>5 мин пешком</span>",
            goType: "people",
            time: "5 мин",
            price: "от 8 до 21.3 млн руб",
            counts: "Осталось 35 квартир",
          },
          {
						img: "/images/quiz/ii4.png",
						date: "Сдача в 2024 г., есть сданные",
            title: "Лучи",
            addr: 'Москва <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 12.5L10 8.5L6 4.5" stroke="#98A2B3" stroke-linecap="round" stroke-linejoin="round"/></svg> ЗАО',
            metro: "Солнцево <span>3 мин пешком</span>",
            goType: "people",
            time: "3 мин",
            price: "от 6.6 до 25.8 млн руб",
            counts: "Осталось 15 квартир",
          },
        ]
      }
      return fakeResult;
    }
  }
}
</script>
