<template>
    <fragment>
      <SendFormModal v-show="isShowModal" v-model="isShowModal">
        <div slot="heading" v-html="renderModalHeading" class="text_slg bold modal__title"/>
        <div style="width: 100%" slot="content">
          <form v-if="!success" novalidate @submit.prevent="checkForm" class="modal__formBox">
            <div class="modal__formContainer">
        
              <div class="modal__formWrapper" :class="{ 'has-error': errors.has('username') }">
                <input
                    type="text"
                    v-model="username"
                    name="username"
										aria-required="true"
                    v-validate="'required|min:3|max:60'"
                    class="modal__form text_md"
                    placeholder="Как вас зовут?"
										autocomplete="off"
                />
                <div v-if="errors.has('username')" class="modal__formError text_xs">
                  Имя должно содержать от 2 до 50 символов
                </div>
              </div>
        
              <div class="modal__formWrapper" :class="{ 'has-error': errors.has('phone') }">
                <input
                    v-model="phone"
                    name="phone"
                    type="text"
                    v-phone
                    maxlength="18"
                    v-validate="{ required: true, regex: phoneRegex }"
                    class="modal__form text_md"
                    placeholder="Номер телефона*"
                    autocomplete="off"
                    @keyup="eventLastLetter"
                />
                <div v-if="errors.has('phone')" class="modal__formError text_xs">
                  Необходимо заполнить поле «Телефон» в формате +7 (xxx) xxx-xx-xx
                </div>
              </div>
            </div>
      
            <div v-if="error" class="alert alert-danger" v-html="error"></div>
      
            <button
                :disabled="loader"
                type="submit"
                class="button_def button__size button__type_1 text_md medium"
            >
              Отправить
            </button>
						<div class="modal-form-copy">Нажимая на кнопку, Вы даете согласие на обработку своих персональных данных.</div>
          </form>
        </div>
      </SendFormModal>
        <div class="pRel heightAll listing__layoutPart">
            <div class="listing__complete pAbs">
                <img width="24" src="/images/quiz/icon-complete.svg" alt="">
            </div>
            <div class="flex-wrapper flexWrap">
                <div class="text_slg bold listing__title" v-html="renderHeading"/>
                <button v-if="success" class="button_def button__size button__type_1 button__again text_md medium"
                        @click="goHome()">Начать заново
                </button>
            </div>

            <div class="content__wrapper heightAll">
                <div class="content__form">
                    <div class="content__formWrapper">
												<div class="result-text" v-html="renderResult"></div>
                        <list-result  />
                        
                    </div>
                </div>
            </div>
          
            <!--<BuildList :list="result.novos" />-->

            <div class="listing__gradient"></div>
        </div>

    </fragment>
</template>
<style scoped>
.modal__formBox {
  max-width: 660px;
  margin: 0 auto;
}
</style>
<script>
  import {TextHelper} from '@/helpers/TextHelper'
  import SendFormComponent from '@/modules/quiz/components/SendFormComponent'
  import SendFormModal from '@/modules/quiz-landing/components/SendFormModal'
  import BuildList from '@/modules/quiz-landing/components/BuildListComponent'
  import ListResult from '@/modules/quiz-landing/components/ListResult'
  import {Fragment} from 'vue-fragment'

  var ref = document.referrer;

  if (!ref) {
    var param = window
        .location
        .search
        .replace('?','')
        .split('&')
        .reduce(
            function(p,e){
              var a = e.split('=');
              p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
              return p;
            },
            {}
        );

    if (param['utm_source'] !== undefined) {
      if (param['utm_source'].indexOf('tg_') !== -1) {
        ref = 'from tg';
      } else if (param['utm_source'] == 'unisender') {
        ref = 'from email';
      }
    }
  }

  export default {
    name: 'send-form',
    components: {SendFormModal, BuildList, Fragment, ListResult},
    mixins: [SendFormComponent],
    data: () => ({
      isShowModal: false,
      firstref: ref,
    }),
    mounted() {
      // modal will be showing in 2 sec
			document.body.classList.add('finish-step')
      setTimeout(() => this.isShowModal = true, 1000);

//    var id = document.querySelector('[ya-counter]');
//
//    if (id) {
//      window['yaCounter' + id.getAttribute('ya-counter')].reachGoal('quiz_request');
//    }
    },
    methods: {
      triggerEvent() {
      }
    },
    computed: {
      renderHeading() {
        const build = TextHelper.declensionWords(this.result.counts.builds, ['новостройка', 'новостройки', 'новостроек']);
      },
			renderResult(){
				const build = TextHelper.declensionWords(this.result.counts.builds, ['новостройка', 'новостройки', 'новостроек']);
				const find = TextHelper.declensionWords(this.result.counts.builds, ['найдена', 'найдено', 'найдено']);
				const text = `<span>По вашему запросу ${find}:</span> ${this.result.counts.builds} ${build}. `;
				return text;
			},
      renderModalHeading() {
        if (this.success) {
          // не закрываем после отправки
          // this.isShowModal = false;
        }

        const build = TextHelper.declensionWords(this.result.counts.builds, ['новостройка', 'новостройки', 'новостроек']);
        const find = TextHelper.declensionWords(this.result.counts.builds, ['найдена', 'найдено', 'найдено']);
        const text = `Для вас ${find} ${this.result.counts.builds} ${build}. `;
	
				// return !this.success
				//   ? text + `Оставьте свои контакты, чтобы увидеть все результаты`
				//   : 'Спасибо, вы получите результаты совсем скоро! У вас есть возможность изменить параметры и сделать новый подбор.'
				return !this.success
				  ? `<div class="modal__title-title">Последний шаг!</div><div class="modal-text">Оставьте свой номер телефона и независимый эксперт Федерального портала недвижимости подберет вам квартиру. Бесплатно</div>`
				  : 'Спасибо, вы получите результаты совсем скоро! У вас есть возможность изменить параметры и сделать новый подбор.'
      }
    },
  }
</script>
