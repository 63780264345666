<template>
	<div class="qz-quiz__wrapper" :class="{'finish-step' : stat.levels == stat.level}" key="t1">
		<main class="qz-quiz__main" :class="{finish : formData && !stat.level}">
			<div v-if="formData && stat.level" class="qz-form">
				<div class="qz-form-wrapper">
					<div class="qz-form__steps">
						<div class="qz-step-counter">Шаг {{ stat.level }} из {{ stat.levels }}</div>
					</div>
					<div v-if="formData.heading" class="qz-form-title">{{ formData.heading }}</div>
					<div class="qz-form__content">
						
						<template v-if="formData.fields">
							<dynamic-form/>
						</template>
						
						<template v-else-if="formData.component">
							<Transition><component :is="formData.component"/></Transition>
						</template>
					
					</div>
				</div>
			</div><!--end qz-form -->
			<component v-else-if="formData && !stat.level" :is="formData.component"/>
			<div class="bg-kvartir"></div>
		</main>
	</div>
</template>

<script>
  import Quiz from '../quiz/App'
  import DynamicForm from '@/modules/quiz-landing/components/DynamicFormComponent'
  import PriceForm from '@/modules/quiz-landing/components/PriceFormComponent'
  import PriceFormRadio from '@/modules/quiz-landing/components/PriceFormRadioComponent'
  import SendForm from '@/modules/quiz-landing/components/SendFormComponent'
  import FinishingFormComponent from '@/modules/quiz-landing/components/FinishingFormComponent'

  export default {
    mixins: [Quiz],
    components: {
      'dynamic-form': DynamicForm,
      'price-form': PriceForm,
      'price-form-radio': PriceFormRadio,
      'send-form': SendForm,
      'finishing-form': FinishingFormComponent
    },
    methods: {
      progressBar (val) {
        return val + ' 100';
      }
    }
  }
</script>
